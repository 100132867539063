import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { SORT_DIRECTION } from '@/constants/common.const';
import { ApiMethod } from '@/enums/api.enum';
import type {
  PostProductRatingAnswerRequest,
  PostProductRatingAnswerResponse,
  PostRatingProductsVerifyRequestApiRequest,
  PostRatingRevisionProductRequest,
  PostRatingsGlobalProductProductsRequest,
  RegisterProductDirectRatingRequest,
  SaveQuestionnaireReviewRequest,
  StartSurveyRequest,
  SubmitQuestionnaireReviewRequest,
  UpdateContentModificationRequest
} from '@/types/rating/rating.request.type';
import type {
  DetailProductRatingResponse,
  DraftQuestionnaireReviewRequest,
  DraftQuestionnaireReviewResponse,
  DraftQuestionnaireReviewSurvey,
  ExplanationResponse,
  GroupRatingObjectionResponse,
  PostRatingRevisionProductResponse,
  QuestionnaireReviewRequest,
  QuestionnaireReviewResponse,
  RatingHistoryResponse,
  RatingProductResponse,
  RatingProductsGameKeyResponse,
  RatingProductsLaunchInfoResponse,
  RatingSearchResponse,
  ReportGameContentChangeResponse
} from '@/types/rating/rating.response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458916895
export const fetchRatingsProductsApi = async (
  productNo: string
): Promise<RatingProductResponse | undefined> => {
  const res = await useRequest<RatingProductResponse>(`${apiUrl}/ratings/products/${productNo}`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    showCommonError: false
  });

  if (res.status !== 0) {
    return;
  }

  return res.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458916894#99aed61e-b96e-4334-a955-f9963a75bd90-458924726
export const searchRatingWithRatingClassificationApi = async (
  ratingId: string
): Promise<RatingSearchResponse | undefined> => {
  const res = await useRequest<RatingSearchResponse>(`${apiUrl}/ratings/direct/info`, {
    params: camelToSnake({ ratingId }),
    showCommonError: true
  });

  return res.data;
};

// Save draft rating
// https://wiki.smilegate.net/pages/viewpage.action?pageId=458920365
export const saveDraftQuestionnaireReviewApi = async (
  productNo: string,
  data: SaveQuestionnaireReviewRequest
): Promise<{ success: boolean } | undefined> => {
  const res = await useRequest<{ success: boolean }>(
    `${apiUrl}/ratings/products/${productNo}/answer/save`,
    {
      method: ApiMethod.Post,
      data: camelToSnake(data),
      showCommonError: true
    }
  );

  return res?.data;
};

// Get draft rating
// https://wiki.smilegate.net/pages/viewpage.action?pageId=458923657
export const fetchDraftQuestionnaireReviewApi = async (
  productNo: string,
  params: DraftQuestionnaireReviewRequest
): Promise<DraftQuestionnaireReviewResponse | undefined> => {
  const res = await useRequest<DraftQuestionnaireReviewResponse>(
    `${apiUrl}/ratings/products/${productNo}/answer/save`,
    {
      params: camelToSnake(params)
    }
  );

  return res?.data;
};

// Save rating
// https://wiki.smilegate.net/pages/viewpage.action?pageId=458920400
export const submitQuestionnaireReviewApi = async (
  productNo: string,
  data: SubmitQuestionnaireReviewRequest
): Promise<ReportGameContentChangeResponse | undefined> => {
  const res = await useRequest<ReportGameContentChangeResponse>(
    `${apiUrl}/ratings/products/${productNo}/answer`,
    {
      method: ApiMethod.Post,
      data: camelToSnake(data),
      showCommonError: true
    }
  );

  return res?.data;
};

// Get Rating
// https://wiki.smilegate.net/pages/viewpage.action?pageId=458924010
export const fetchQuestionnaireReviewApi = async (
  productNo: string,
  params: QuestionnaireReviewRequest
): Promise<QuestionnaireReviewResponse | undefined> => {
  const res = await useRequest<QuestionnaireReviewResponse>(
    `${apiUrl}/ratings/products/${productNo}/answer`,
    {
      params: camelToSnake(params)
    }
  );

  return res?.data;
};

// Get Survey
// https://wiki.smilegate.net/pages/viewpage.action?pageId=458917294
export const fetchQuestionnaireContentApi = async (): Promise<
  DraftQuestionnaireReviewSurvey | undefined
> => {
  const res = await useRequest<DraftQuestionnaireReviewSurvey>(`${apiUrl}/ratings/survey`);

  return res?.data;
};

// Get rating detail
// https://wiki.smilegate.net/pages/viewpage.action?pageId=458922876
export const getDetailProductRatingApi = async (
  productNo: string
): Promise<DetailProductRatingResponse | undefined> => {
  const res = await useRequest<DetailProductRatingResponse>(
    `${apiUrl}/ratings/detail/products/${productNo}`,
    {
      showCommonError: false
    }
  );

  return res?.data;
};

// delete rating
// https://wiki.smilegate.net/pages/viewpage.action?pageId=458921384
export const deleteGracRatingApi = async (
  productNo: string
): Promise<{ success: boolean } | undefined> => {
  const res = await useRequest<{ success: boolean }>(
    `${apiUrl}/ratings/request-rating/products/${productNo}`,
    {
      method: ApiMethod.Delete
    }
  );

  return res?.data;
};

// start survey
// https://wiki.smilegate.net/pages/viewpage.action?pageId=458918141
export const startSurveyApi = async (
  productNo: string,
  data: StartSurveyRequest
): Promise<{ success: boolean } | undefined> => {
  const res = await useRequest<{ success: boolean }>(
    `${apiUrl}/ratings/products/${productNo}/survey/start`,
    {
      method: ApiMethod.Post,
      data: camelToSnake(data),
      showCommonError: false
    }
  );

  return res?.data;
};

// Rating history
// https://wiki.smilegate.net/pages/viewpage.action?pageId=458926024
export const fetchUsageRatingHistoryApi = async (
  productNo: string
): Promise<RatingHistoryResponse | undefined> => {
  const res = await useRequest<RatingHistoryResponse>(
    `${apiUrl}/ratings/histories/products/${productNo}`,
    {
      showCommonError: false,
      params: camelToSnake({
        createdAt: SORT_DIRECTION.DESC
      })
    }
  );

  if (res.status !== 0) {
    return;
  }

  return res?.data;
};

// Objection application
// https://wiki.smilegate.net/pages/viewpage.action?pageId=458924444
export const postProductRatingAnswerApi = async (
  productNo: string,
  data: PostProductRatingAnswerRequest
): Promise<PostProductRatingAnswerResponse | undefined> => {
  const res = await useRequest<PostProductRatingAnswerResponse>(
    `${apiUrl}/ratings/objection-answer/products/${productNo}`,
    {
      method: ApiMethod.Post,
      data: camelToSnake(data),
      showCommonError: true
    }
  );

  return res?.data;
};

// Post look up rating
// https://wiki.smilegate.net/pages/viewpage.action?pageId=466619167
export const registerProductDirectRatingApi = async (
  productNo: string,
  data: RegisterProductDirectRatingRequest
): Promise<{ success: boolean } | undefined> => {
  const res = await useRequest<{ success: boolean }>(
    `${apiUrl}/ratings/direct/product/${productNo}`,
    {
      method: ApiMethod.Post,
      data: camelToSnake(data),
      showCommonError: true
    }
  );

  return res?.data;
};

// Post global - international organzine rating
// https://wiki.smilegate.net/pages/viewpage.action?pageId=466634622
export const postRatingsGlobalProductsApi = async (
  productNo: string,
  data: PostRatingsGlobalProductProductsRequest
): Promise<{ success: boolean } | undefined> => {
  const res = await useRequest<{ success: boolean }>(
    `${apiUrl}/ratings/global/products/${productNo}`,
    {
      method: ApiMethod.Post,
      data: camelToSnake(data)
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458916894#99aed61e-b96e-4334-a955-f9963a75bd90-458924435
export const acceptRatingApi = async (
  productNo: string,
  data: {
    ratingType: string;
  }
): Promise<{ success: boolean } | undefined> => {
  const res = await useRequest<{ success: boolean }>(
    `${apiUrl}/ratings/accept/products/${productNo}`,
    {
      method: ApiMethod.Post,
      data: camelToSnake(data)
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=510877753
export const fetchExplanationApi = async (
  productNo: string
): Promise<ExplanationResponse | undefined> => {
  const res = await useRequest<ExplanationResponse>(
    `${apiUrl}/ratings/explanation/products/${productNo}`,
    {
      method: ApiMethod.Get
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458916894#99aed61e-b96e-4334-a955-f9963a75bd90-458924812
export const applyExplanationApi = async (
  productNo: string,
  data: {
    explanation: string;
  }
): Promise<{ success: boolean } | undefined> => {
  const res = await useRequest<{ success: boolean }>(
    `${apiUrl}/ratings/explanation/products/${productNo}`,
    {
      method: ApiMethod.Post,
      data: camelToSnake(data)
    }
  );

  return res?.data;
};

// Post revision
// https://wiki.smilegate.net/pages/viewpage.action?pageId=458924667
export const postRatingsRevisionProductsApi = async (
  productNo: string,
  data: PostRatingRevisionProductRequest
): Promise<PostRatingRevisionProductResponse | undefined> => {
  const res = await useRequest<PostRatingRevisionProductResponse>(
    `${apiUrl}/ratings/revision/products/${productNo}`,
    {
      method: ApiMethod.Post,
      data: camelToSnake(data),
      showCommonError: false
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=472594398
export const fetchRatingProductGameKeyApi = async (
  productNo: string
): Promise<RatingProductsGameKeyResponse | undefined> => {
  const res = await useRequest<RatingProductsGameKeyResponse>(
    `${apiUrl}/ratings/products/${productNo}/game-key`,
    {
      showCommonError: false
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=478338627
export const fetchRatingProductLaunchInfoApi = async (
  productNo: string
): Promise<RatingProductsLaunchInfoResponse | undefined> => {
  const res = await useRequest<RatingProductsLaunchInfoResponse>(
    `${apiUrl}/ratings/products/${productNo}/launch-info`,
    {
      showCommonError: false
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=491585900
export const postRatingProductsVerifyRequestApi = async (
  productNo: string,
  data: PostRatingProductsVerifyRequestApiRequest
): Promise<{ success: boolean } | undefined> => {
  const res = await useRequest<{ success: boolean }>(
    `${apiUrl}/ratings/products/${productNo}/verify-request`,
    {
      method: ApiMethod.Post,
      data: camelToSnake(data),
      showCommonError: true
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=509380902
export const updateContentModification = async (
  productNo: string,
  data: UpdateContentModificationRequest
): Promise<{ success: boolean } | undefined> => {
  const res = await useRequest<{ success: boolean } | undefined>(
    `${apiUrl}/ratings/reclassification/status/products/${productNo}`,
    {
      method: ApiMethod.Put,
      data: camelToSnake(data)
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=517335783
export const checkGroupHasRatingObjection = async (
  groupId: string
): Promise<GroupRatingObjectionResponse | undefined> => {
  const res = await useRequest<GroupRatingObjectionResponse>(
    `${apiUrl}/ratings/groups/${groupId}/objection/check`,
    {
      method: ApiMethod.Get
    }
  );

  return res?.data;
};
